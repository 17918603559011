import instance  from '@/utils/api'
/**
 * 查询btc的返回
 * @returns 
 */
export function queryBtcInfo() {
    return instance({
        url: "/btc/queryList",
        method: 'post'
    }).then(res => {
        return res; // 返回从后端获取的数据
    }).catch(error => {
        console.error(error);
        throw error;
    });
}