import { createRouter, createWebHistory } from 'vue-router'
import BtcView from '../views/BtcView.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/article/ArticleView_List.vue')
  },
  {
    path: '/article/:id',
    component: () => import(/* webpackChunkName: "about" */ '../views/article/ArticleView_Show.vue'),
    props: true // 将路由参数作为组件的 props 传递
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/btc',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: BtcView,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/article',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/article/ArticleView_Add.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/article/edit/:id',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/article/ArticleView_Edit.vue'),
    meta: { requiresAuth: true },
    props: true // 将路由参数作为组件的 props 传递

  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFoundView.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next)=>{
  
  if(to.meta.requiresAuth && !store.state.isLoggedIn){
    // 如果路由需要登录权限且用户未登录，则重定向到登录页面
    next('/login');
  }else{
    // 允许用户继续访问该路由
    next();
  }
})
export default router
