/**
 * 获取token
 */
export function getToken(){
    const token = sessionStorage.getItem("cmm_token");
    if(token == null){
        return null;
    }else{
        return token;
    }
}
/**
 * 
 * @param {设置token} token 
 */
export function setToken(token){
    sessionStorage.setItem("cmm_token",token);
}
/**
 * 是否登录
 * @param {*} token 
 */
export function setIsLoggedIn(isLoggedIn){
    sessionStorage.setItem("isLoggedIn",isLoggedIn);
}

export function getIsLoggedIn(){
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    if(isLoggedIn == null){
        return false;
    }else{
        return isLoggedIn;
    }
}
/**
 * 获取token
 */
export function getLoginUserInfo(){
    const user = sessionStorage.getItem("cmm_login_user");
    if(user == null){
        return null;
    }else{
        return JSON.parse(user);
    }
}
/**
 * 
 * @param {设置user} user 
 */
export function setLoginUserInfo(user){
    sessionStorage.setItem("cmm_login_user",JSON.stringify(user));
}