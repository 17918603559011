import { createStore } from 'vuex'
import { getToken, setToken, getIsLoggedIn, setIsLoggedIn,getLoginUserInfo,setLoginUserInfo } from '@/utils/persistence'

export default createStore({
  state: {
    token: getToken(),
    userRole: null,
    menuTree: null,
    isLoggedIn: getIsLoggedIn(),
    userInfo:getLoginUserInfo(),
  },
  getters: {
    getToken: state => state.token,
    getUserRole: state => state.userRole,
    getMenuTree: state => state.menuTree,
    getIsLoggedIn: state => state.isLoggedIn,
    getUserInfo: state => state.userInfo
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      state.isLoggedIn = true;
      setToken(token);
      setIsLoggedIn(true);
    },
    LOGIN_OUT(state){
      state.token = null;
      state.isLoggedIn = false;
      setToken(null);
      setIsLoggedIn(false);
      setLoginUserInfo(null);
      state.userInfo = null;
    },
    SET_USER_ROLE(state, userRole) {
      state.userRole = userRole
    },
    SET_MENU_TREE(state, menuTree) {
      state.menuTree = menuTree
    },
    SET_IS_LOGGED_IN(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
      setLoginUserInfo(userInfo);
    }
  },
  actions: {
  },
  modules: {
  }
})
