<template>
  <div ref="chart" style="width: 100%; height: 100vh;"></div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import * as echarts from 'echarts';
import { queryBtcInfo } from '@/api/btc'

const chart = ref();

const initChart = (data) => {
  // 提取 price 属性数组
  let priceArray = data.map(function (item) {
    return item.price;
  });
  // 找出最大值
  let maxPrice = Math.max(...priceArray);

  // 找出最小值
  let minPrice = Math.min(...priceArray);
  let minValue = minPrice-200;
  let maxValue =minPrice+(Math.floor((maxPrice-minPrice)/200))*200+200;
  // 提取 createDate 集合
  let createDateSet = data.map(function (item) {
    return item.createDate;
  });
  // 基于准备好的dom，初始化echarts实例
  let myChart = echarts.init(chart.value);

  // 指定图表的配置项和数据
  let option = {
    title: {
      text: 'btc五分钟价格表'
    },
    tooltip: {},
    xAxis: {
      name: "日期",
      data: createDateSet,
      show: true
    },
    yAxis: {
      interval: 200,
      min: minValue,
      max: maxValue
    },
    series: [
      {
        name: '价格',
        type: 'line',
        data: priceArray,
        label: {
            show: true, // 显示标签
            position: 'top' // 标签位置
        }
      }
    ]
  };
  // 使用刚指定的配置项和数据显示图表
  myChart.setOption(option);
}

onMounted(() => {
  queryBtcInfo().then(res => {
    if (res.success) {
      initChart(res.data);
    }
  }).catch(() => {

  })
})
</script>
