import axios from 'axios'
import { ElMessage, ElLoading } from 'element-plus'
import store from '@/store'
import router from '@/router'
const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})
let loadingInstance

instance.interceptors.request.use(config => {
    let token = store.state.token;
    if ("/login".indexOf(config.url) === -1 && token) {
        config.headers.AUTHORIZE_TOKEN = token
    }
    // 在请求开始时显示loading
    loadingInstance = ElLoading.service({ fullscreen: true })
    return config
}, error => {
    return Promise.reject(error)
})

instance.interceptors.response.use(response => {
    loadingInstance.close();
    if (response.data.success) {
        // 如果请求地址是login且响应对象的success为true，则将data存储到Vuex
        // store.commit('SET_USER_DATA', response.data.data)
    } else {
        if (response.data.code == 401) {
            ElMessage({
                showClose: true,
                message: response.data.desc,
                type: 'error',
            });
            router.push("/login");
        } else {
            // 如果响应对象的success为false，使用Element Plus提示框提示失败
            // ElMessage.error(response.data.desc);
            ElMessage({
                showClose: true,
                message: response.data.desc,
                type: 'error',
            })
        }
    }
    return response.data;
}, error => {
    loadingInstance.close();
    ElMessage.error(error.message);
    return Promise.reject(error)
})

export default instance